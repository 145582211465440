var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-overlay',{staticClass:"planning_detail",attrs:{"value":_vm.show}},[_c('v-card',{attrs:{"light":""}},[_c('img',{attrs:{"id":"close","src":require("@/assets/close.png")},on:{"click":function($event){_vm.$emit('close'), _vm.cubageTotal = 0, _vm.poidsTotal = 0}}}),_c('h3',{staticClass:"mt-5 uppercasePopup title_back"},[_vm._v(_vm._s(_vm.title))]),_c('h4',{staticClass:"mt-5"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset":""}},[_c('v-data-table',{staticClass:"ml-6 mt-3 mr-6 tablee",attrs:{"headers":_vm.headers,"items":_vm.planningDetails,"hide-default-header":"","hide-default-footer":"","no-data-text":"Aucune donnée disponible"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return _vm._l((headers),function(item){return _c('th',{key:item.value,staticClass:"column"},[_vm._v(" "+_vm._s(item.text.toUpperCase()))])})}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-left",staticStyle:{"font-size":"1.20rem"}},[_vm._v(_vm._s(item.client))])]}},{key:"item.nbitem",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-left pl-6 data"},[_vm._v(_vm._s(item.nbitem))])]}},{key:"item.cubage",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-left data"},[_vm._v(_vm._s(item.cubage.toFixed(2))+"m³")])]}},{key:"item.poids",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-left  data"},[_vm._v(_vm._s(item.poids)+"Kg")])]}}],null,true)})],1)],1),_c('v-row',[_c('div',{staticClass:"line_planning ml-10 mr-10"})]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{staticClass:"total",attrs:{"cols":"4"}},[_vm._v("CUBAGE TOTAL: "+_vm._s(_vm.cubageTotall)+"m³")]),_c('v-col',{staticClass:"total text-left",attrs:{"cols":"3"}},[_vm._v("POIDS TOTAL: "+_vm._s(_vm.poidsTotall)+"T")]),_c('v-col',{attrs:{"cols":"1"}})],1),_c('v-row',{staticStyle:{"height":"40px"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }