<template>
  <v-row no-gutters>
    <v-col class="mt-3" cols="12">
      <accueil :title="title" />
    </v-col>
  </v-row>
</template>

<script>
import accueil from "@/components/admin/dashboard/accueil";

export default {
  name: "Dashboard",

  data() {
    return {
      title: "PLANNING DU JOUR",
    };
  },

  components: {
    accueil,
  },
};
</script>

<style scoped>
@import "dashboard.css";
</style>
